import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Slide } from "@material-ui/core";
import FHSnackBar, { SNACKBAR } from "../FHSnackBar/FHSnackBar";

const CustomAlert = (props) => {
	const { alerts, handleAlertClose } = props;

	let alert = alerts && alerts.length ? alerts[0] : null;

	let titleJSX = alert.title ? <AlertTitle>{alert.title}</AlertTitle> : "";

	let alertJSX;
	if (alert.type === SNACKBAR) {
		alertJSX = (
			<FHSnackBar message={alert.message} severity={alert.severity} />
		);
	} else {
		alertJSX = (
			<Slide
				direction="up"
				in={alerts.length !== 0}
				mountOnEnter
				unmountOnExit
				timeout={1000}
			>
				<Alert
					severity={alert.severity}
					onClose={() => {
						handleAlertClose(0);
					}}
				>
					{titleJSX}
					{alert.message}
				</Alert>
			</Slide>
		);
	}

	return alertJSX;
};

export default CustomAlert;
