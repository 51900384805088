import axios from "axios";
import { MOBILE_CLASS_API_HOST } from "./shared/consts";
import { SIGNIN, FORBIDDEN } from "./shared/routes";

const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;

const instance = axios.create({
	baseURL: MOBILE_CLASS_API_HOST,
});

instance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === STATUS_UNAUTHORIZED) {
			if (window.location.pathname !== SIGNIN)
				window.location.href = SIGNIN;
		} else if (error.response.status === STATUS_FORBIDDEN) {
			window.location.href = FORBIDDEN;
		}

		return Promise.reject(error);
	}
);

export default instance;
