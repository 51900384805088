import React from "react";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright() {
	return (
		<React.Fragment>
			<Typography variant="body2" color="textSecondary" align="center">
				{"Copyright © "}
				<Link
					color="inherit"
					href="https://www.facebook.com/mobileclass.net"
				>
					Mobile Class Private Limited
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
		</React.Fragment>
	);
}
