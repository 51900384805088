import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";

import { COURSE_DETAILS, HOW_IT_WORKS } from "../../shared/routes";
import { lang } from "../../shared/utility";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		background: theme.palette.footer.default,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		color: theme.palette.footer.text,
	},
	button: {
		color: theme.palette.footer.text,
	},
}));

const GetInTouch = (props) => {
	const classes = useStyles();

	return (
		<Grid
			container
			direction="column"
			justify="flex-start"
			alignItems="center"
			className={classes.root}
		>
			<Grid item xs={12}>
				<Typography variant="h6">
					{lang("footer.getintouch.title")}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<ul>
					<li>
						<Typography>
							{lang("footer.getintouch.moreinfo")}
							<Button
								href={COURSE_DETAILS}
								className={classes.button}
							>
								Click here
								{/*TODO: Pass the pathname to redirect*/}
							</Button>
						</Typography>
					</li>
					<li>
						<Typography>
							{lang("footer.getintouch.videoexplainer")}
							<Button
								className={classes.button}
								href={HOW_IT_WORKS}
							>
								Click here
								{/*TODO: Pass the pathname to redirect*/}
							</Button>
						</Typography>
					</li>
				</ul>
			</Grid>
		</Grid>
	);
};

export default GetInTouch;
