const currentYear = new Date().getFullYear();

export const AUTHMETHOD_SIGN_IN = "SIGN_IN";
export const AUTHMETHOD_SIGN_UP = "SIGN_UP";
export const AUTHMETHOD_FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const getInputConfig = (type) => {
	let config = {
		elementType: "input",
		propsToPass: {
			type: "text",
			autoComplete: "off",
			id: "",
			label: "",
			name: "",
			autoFocus: false,
			variant: "outlined",
			margin: "normal",
			required: true,
			fullWidth: true,
			error: false,
		},
		validation: {
			required: true,
		},
		isRequiredInSignIn: false,
		isRequiredInSignUp: true,
	};

	switch (type) {
		case "email":
			config.propsToPass.type = config.propsToPass.autoComplete = config.propsToPass.id = config.propsToPass.name =
				"email";
			config.propsToPass.label = "Email";
			config.propsToPass.autoFocus = config.validation.isEmail = config.isRequiredInSignIn = true;
			break;
		case "name":
			config.propsToPass.autoComplete = config.propsToPass.id = config.propsToPass.name =
				"name";
			config.propsToPass.label = "Name";
			config.validation.minLength = 3;
			config.validation.maxLength = 60;
			break;
		// Todo: Make password -> new-password, confirm-password, current-password
		case "password":
			config.propsToPass.type = config.propsToPass.id = config.propsToPass.name =
				"password";
			config.propsToPass.autoComplete = "current-password";
			config.propsToPass.label = "Password";
			config.validation.minLength = 6;
			config.validation.maxLength = 40;
			config.isRequiredInSignIn = true;
			break;
		case "confirmPassword":
			config.propsToPass.type = "password";
			config.propsToPass.autoComplete = "new-password";
			config.propsToPass.id = config.propsToPass.name = "confirmPassword";
			config.propsToPass.label = "Confirm password";
			config.validation.minLength = 6;
			config.validation.maxLength = 40;
			config.isRequiredInSignIn = false;
			break;
		case "address":
			config.propsToPass.autoComplete = "street-address";
			config.propsToPass.id = config.propsToPass.name = "address";
			config.propsToPass.label = "Address";
			config.validation.minLength = 5;
			config.validation.maxLength = 200;
			break;
		case "phone":
			config.propsToPass.type = "number";
			config.propsToPass.autoComplete = "tel";
			config.propsToPass.id = config.propsToPass.name = "phone";
			config.propsToPass.label = "Phone";
			config.validation.minLength = 10;
			config.validation.maxLength = 10;
			break;
		case "fixedLine":
			config.propsToPass.type = "number";
			config.propsToPass.autoComplete = "tel";
			config.propsToPass.id = config.propsToPass.name = "fixedLine";
			config.propsToPass.label = "Fixed Line Number";
			config.validation.minLength = 10;
			config.validation.maxLength = 10;
			break;
		case "yearOfExam":
			config.elementType = "dropdown";
			config.propsToPass.type = "number";
			config.propsToPass.id = config.propsToPass.name = "yearOfExam";
			config.propsToPass.label = "Exam Year";
			config.validation.minLength = 4;
			config.validation.maxLength = 4;
			config.validation.valueMin = currentYear;
			config.validation.valueMax = currentYear + 3;
			config.values = [
				currentYear,
				currentYear + 1,
				currentYear + 2,
				currentYear + 3,
			];
			break;
		case "school":
			config.propsToPass.id = config.propsToPass.name = "school";
			config.propsToPass.label = "School";
			config.validation.minLength = 3;
			config.validation.maxLength = 30;
			break;
		default:
			break;
	}

	return config;
};
