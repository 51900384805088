import axios from "../../axios-mobile-class";

import { AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT } from "./actionTypes";
import { getDeviceTokenKey, updateObject } from "../../shared/utility";
import {
	AUTHMETHOD_SIGN_IN,
	AUTHMETHOD_SIGN_UP,
} from "../../container/Auth/utils";
import { login, register } from "../../api/authAPI";
import { authRequestTimeoutSec } from "../../shared/consts";

let authRequestInterceptor;

const authSuccess = (token, id, deviceUuid) => {
	axios.interceptors.request.eject(authRequestInterceptor);
	authRequestInterceptor = axios.interceptors.request.use((request) => {
		request.headers.Authorization = `Bearer ${token}`;
		request.headers.deviceUuid = deviceUuid;
		return request;
	});

	return {
		type: AUTH_SUCCESS,
		idToken: token,
		userId: id,
		deviceUuid: deviceUuid,
		error: undefined,
	};
};

const authFail = (error) => {
	return {
		type: AUTH_FAIL,
		error: error,
	};
};

const checkAuthTimeout = (expirationTime) => (dispatch) => {
	setTimeout(() => {
		dispatch(authLogout());
	}, expirationTime * 1000);
};

/*
 * Retrieve the device-uuid using the email.
 * If the email has an upper case, but the key of the token is in lower case
 * then, convert the email into lower case and retrieve the uuid value
 */
const getDeviceToken = (email) => {
	let deviceUuid = localStorage.getItem(getDeviceTokenKey(email));

	if (!deviceUuid) {
		deviceUuid = localStorage.getItem(
			getDeviceTokenKey(email.toLowerCase())
		);
	}
	return deviceUuid;
};

export const authLogout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("userId");
	localStorage.removeItem("expirationDate");
	axios.interceptors.request.eject(authRequestInterceptor);
	return {
		type: AUTH_LOGOUT,
	};
};

const authSuccessRoutine = (dispatch, response) => {
	if (response.data) {
		const expirationDate = new Date(
			new Date().getTime() + authRequestTimeoutSec * 1000
		);
		localStorage.setItem("token", response.data.token);
		localStorage.setItem("userId", response.data.email);
		localStorage.setItem("expirationDate", expirationDate);
		let deviceUuid;
		if (response.data.deviceUuid) {
			localStorage.setItem(
				getDeviceTokenKey(response.data.email),
				response.data.deviceUuid
			);
			deviceUuid = response.data.deviceUuid;
		} else {
			deviceUuid = getDeviceToken(response.data.email);
		}
		dispatch(
			authSuccess(response.data.token, response.data.email, deviceUuid)
		);
		dispatch(checkAuthTimeout(authRequestTimeoutSec));
	}
};

const authFailRoutine = (dispatch, error) => {
	if (error.response !== undefined) {
		dispatch(authFail(error.response.data.message));
	} else {
		dispatch(authFail("Server is under maintainance. Try again shortly."));
	}
};

export const auth = (data) => (dispatch) => {
	let authData = data.payload;
	const email = authData.email;
	authData = updateObject(authData, {
		deviceUuid: getDeviceToken(email),
	});

	switch (data.authMethod) {
		case AUTHMETHOD_SIGN_IN:
			login(authData)
				.then((response) => {
					authSuccessRoutine(dispatch, response);
				})
				.catch((error) => {
					authFailRoutine(dispatch, error);
				});
			break;
		case AUTHMETHOD_SIGN_UP:
			register(authData)
				.then((response) => {
					authSuccessRoutine(dispatch, response);
				})
				.catch((error) => {
					authFailRoutine(dispatch, error);
				});
			break;
		default:
			break;
	}
};

export const authCheckState = () => (dispatch) => {
	const token = localStorage.getItem("token");
	if (!token) {
		dispatch(authLogout());
	} else {
		const expirationDate = new Date(localStorage.getItem("expirationDate"));
		if (expirationDate <= new Date()) {
			dispatch(authLogout());
		} else {
			const userId = localStorage.getItem("userId");
			const deviceUuid = localStorage.getItem(getDeviceTokenKey(userId));
			dispatch(authSuccess(token, userId, deviceUuid));
			dispatch(
				checkAuthTimeout(
					(expirationDate.getTime() - new Date().getTime()) / 1000
				)
			);
		}
	}
};
