// First level
export const LANDING = "/";
export const SIGNIN = "/signin";
export const SIGNUP = "/signup";
export const SUBJECT = "/factory";
export const SUBJECT_LIST = "/factories";
export const MY_SUBJECTS = "/enrolled-factories";
export const MY_PROFILE = "/my-profile";
export const MY_PURCHASES = "/my-purchases";
export const LESSONS = "/lessons";
export const CHECKOUT = "/checkout";
export const PAYMENTS = "/payments";
export const COURSE_DETAILS = "/course-details";
export const FORBIDDEN = "/forbidden";
export const RESTRICTED = "/restricted";
export const TOPIC_EXPLAINER = "/topic-explainer";
export const HOW_IT_WORKS = "/how-it-works";
export const PAYMENT_PROOF = "/payment-proof";
export const VERIFY_EMAIL = "/verify-email";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_RESETTED = "/password-reset-successful";
export const AUTH_CHECK = "/authcheck";

// Second level
export const TOPICS = "topics";
export const TOPIC = "topic";

// Query params
export const CHECKOUT_subject = "subject";
