import React, { useEffect } from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import getAlertFromError from "../shared/errorAdapter";
import { addAlert } from "../store/actions/index";

const withErrorhandler = (WrappedComponent, axios) => {
	return (props) => {
		useEffect(() => {
			const resInterceptor = axios.interceptors.response.use(
				(res) => res,
				(error) => {
					props.addAlert(getAlertFromError(error));
					return Promise.reject(error);
				}
			);

			return () => {
				axios.interceptors.response.eject(resInterceptor);
			};
		});

		return <WrappedComponent {...props} />;
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addAlert: (alert) => dispatch(addAlert(alert)),
	};
};

const composedWithErrorHandler = compose(
	connect(null, mapDispatchToProps),
	withErrorhandler
);

export default composedWithErrorHandler;
