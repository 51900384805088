import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Fab, Toolbar, useScrollTrigger, Zoom } from "@material-ui/core";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@material-ui/icons";

import Alert from "../components/UI/FHAlert/FHAlert";
import AppBar from "../components/Navigation/AppBar";
import ConsoleChecker from "../components/UI/ConsoleChecker";

import * as routes from "../shared/routes";
import { removeAlert, clearAlerts } from "../store/actions/index";

const noAppBarPaths = [
	routes.LANDING,
	routes.SIGNIN,
	routes.SIGNUP,
	routes.FORGOT_PASSWORD,
];

const useStyles = makeStyles((theme) => ({
	content: {
		//used to make the footer sticky
		flex: "1 0 auto",
	},
	scrollTop: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	alertWrap: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	alert: {
		width: "90%",
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

function ScrollTop(props) {
	const { children } = props;
	const classes = useStyles();
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 500,
	});

	const handleClick = (event) => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	return (
		<Zoom in={trigger}>
			<div
				onClick={handleClick}
				role="presentation"
				className={classes.scrollTop}
			>
				{children}
			</div>
		</Zoom>
	);
}

const Layout = (props) => {
	const {
		alerts,
		removeAlert,
		clearAlerts,
		isAuthenticated,
		children,
	} = props;

	const classes = useStyles();

	const location = useLocation();

	const [appBarHeight, setAppBarHeight] = useState(0);
	const consoleOpen = ConsoleChecker(); // security checks

	useEffect(() => {
		if (document.getElementById("appBar")) {
			setAppBarHeight(document.getElementById("appBar").clientHeight);
		}
	}, [setAppBarHeight]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, [alerts]);

	useEffect(() => {
		clearAlerts();
	}, [location.pathname, clearAlerts]);

	const handleAlertClose = useCallback(
		(alertId) => {
			removeAlert(alertId);
		},
		[removeAlert]
	);

	const hideAppBar = noAppBarPaths.includes(location.pathname);

	let alertsJSX;
	/*
		Here alert is added only of there are some alerts. If not its removed. This way
		alerts are not affecting the overall styling
	*/
	if (alerts && alerts.length > 0) {
		alertsJSX = (
			<div className={classes.alertWrap}>
				<div className={classes.alert}>
					<Alert
						handleAlertClose={handleAlertClose}
						alerts={alerts}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={classes.content}>
			<Toolbar
				id="back-to-top-anchor"
				disableGutters
				style={{ minHeight: 0 }}
			/>
			{consoleOpen ? <Redirect to={routes.RESTRICTED} /> : ""}
			{hideAppBar ? "" : <AppBar isAuthenticated={isAuthenticated} />}
			<main style={hideAppBar ? {} : { marginTop: `${appBarHeight}px` }}>
				{alertsJSX}
				{children}
				<ScrollTop {...props}>
					<Fab
						color="secondary"
						size="small"
						aria-label="scroll back to top"
					>
						<KeyboardArrowUpIcon />
					</Fab>
				</ScrollTop>
			</main>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		removeAlert: (alertId) => dispatch(removeAlert(alertId)),
		clearAlerts: () => dispatch(clearAlerts()),
	};
};

const mapStateToProps = (state) => {
	return {
		alerts: state.alert.alerts,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
