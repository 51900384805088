import React from "react";

import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
	AppBar,
	Button,
	ButtonBase,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";

import {
	LESSONS,
	MY_PROFILE,
	MY_PURCHASES,
	MY_SUBJECTS,
	SIGNIN,
	SIGNUP,
	SUBJECT,
	SUBJECT_LIST,
} from "../../shared/routes";

//redux: actions
import { authLogout } from "../../store/actions/index";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		[theme.breakpoints.up("md")]: {
			marginRight: theme.spacing(2),
		},
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	appBar: {
		backgroundColor: theme.palette.background.appBar,
		height: theme.spacing(8),
	},
}));

function AppBarWithSearch(props) {
	const classes = useStyles();

	const history = useHistory();
	const { pathname } = useLocation();

	const { onAuthLogout, isAuthenticated } = props;

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleLogout = () => {
		onAuthLogout();
		history.push("/factories");
	};

	let isAuth = isAuthenticated;

	const authWithRedirectPath = (authPath) => `${authPath}?path=${pathname}`;

	const setupButton = (label, routes, variant, color) => (
		<Button
			variant={
				variant
					? variant
					: routes.some((r) => pathname.includes(r))
					? "outlined"
					: "text"
			}
			color={color ? color : "inherit"}
			className={classes.menuButton}
			onClick={() => {
				handleMobileMenuClose();
				history.push(routes[0]);
			}}
		>
			{label ? label : "Button"}
		</Button>
	);

	const joinButton = setupButton(
		"Join Now",
		[authWithRedirectPath(SIGNUP)],
		"contained",
		"primary"
	);

	const loginButton = setupButton(
		"Log In",
		[authWithRedirectPath(SIGNIN)],
		"outlined"
	);

	const mySubjectsButton = setupButton("Class", [MY_SUBJECTS]);

	const myProfile = setupButton("My Profile", [MY_PROFILE]);

	const subjectsButton = setupButton("Subjects", [
		SUBJECT_LIST,
		SUBJECT,
		LESSONS,
	]);

	const purchasesButton = setupButton("Orders", [MY_PURCHASES]);

	const logoutButton = (
		<Button
			color="secondary"
			className={classes.menuButton}
			onClick={() => handleLogout()}
		>
			Logout
		</Button>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = isAuthenticated ? (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>{purchasesButton}</MenuItem>
			<MenuItem>{subjectsButton}</MenuItem>
			<MenuItem>{mySubjectsButton}</MenuItem>
			<MenuItem>{myProfile}</MenuItem>
			<MenuItem>{logoutButton}</MenuItem>
		</Menu>
	) : (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>{loginButton}</MenuItem>
			<MenuItem>{joinButton}</MenuItem>
		</Menu>
	);

	return (
		<div className={classes.grow}>
			<AppBar id="appBar" position="fixed" className={classes.appBar}>
				<Toolbar>
					<ButtonBase
						onClick={() => {
							history.push("/");
						}}
					>
						<div>
							<Logo />
						</div>
					</ButtonBase>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						{isAuth ? purchasesButton : ""}
						{isAuth ? subjectsButton : ""}
						{isAuth ? mySubjectsButton : ""}
						{isAuth ? myProfile : ""}
						{isAuth ? "" : loginButton}
						{isAuth ? "" : joinButton}
						{isAuth ? logoutButton : ""}
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token != null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuthLogout: () => dispatch(authLogout()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBarWithSearch);
