import React from "react";

import logoImage from "./mobile-class-logo.png";

const Logo = () => {
	return (
		<img
			src={logoImage}
			alt="mobile-class-logo"
			width="150"
			height="67.5"
		/>
	);
};

export default Logo;
