import { AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
	token: null,
	userId: null,
	error: undefined,
};

const authSuccess = (state, action) => {
	return updateObject(state, {
		token: action.idToken,
		userId: action.userId,
		deviceUuid: action.deviceUuid,
	});
};

const authFail = (state, action) => {
	return updateObject(state, {
		token: null,
		userId: null,
		error: action.error,
	});
};

const authLogout = (state) => {
	return updateObject(state, {
		token: null,
		userId: null,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_SUCCESS:
			return authSuccess(state, action);
		case AUTH_FAIL:
			return authFail(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
