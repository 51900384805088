import { lang } from "./utility";

// General Const
const MOBILE_CLASS_API_HOST_PROD = "https://api.mobileclass.net";
const MOBILE_CLASS_API__HOST_DEV = "http://localhost:8082";

export const MOBILE_CLASS_API_HOST =
	process.env.NODE_ENV === "development"
		? MOBILE_CLASS_API__HOST_DEV
		: MOBILE_CLASS_API_HOST_PROD;

// Auth Const
export const authRedirectPath = "/factories";
export const authRequestTimeoutSec = 3600;

// LearnOne Api Routes
export const loginRoute = "/v1/auth/login";
export const registerRoute = "/v1/auth/register";

// Device Token
export const deviceTokenKey = "LearnOneDeviceToken";

// Purchase related constants
export const purchaseStates = {
	PENDING_PAYMENT: "PENDING_PAYMENT",
	AWAIT_CONFIRMATION: "AWAIT_CONFIRMATION",
	PURCHASE_APPROVED: "PURCHASE_APPROVED",
	DISPATCHED: "DISPATCHED",
	DELIVERED: "DELIVERED",
	CANCELLED: "CANCELLED",
	REJECTED: "REJECTED",
};

// Purchase related messages
export const purchaseStateMessages = {
	PENDING_PAYMENT: lang("purchase.states.pending.payment"),
	AWAIT_CONFIRMATION: lang("purchase.states.await.confirmation"),
	PURCHASE_APPROVED: lang("purchase.states.purchase.approved"),
	DISPATCHED: lang("purchase.states.dispatched"),
	DELIVERED: lang("purchase.states.delivered"),
	CANCELLED: lang("purchase.states.cancelled"),
	REJECTED: lang("purchase.states.rejected"),
};

// Firebase API Keys and other configurations
export const firebaseConfig = {
	apiKey: "AIzaSyANSVxVKjeWZNYbtA69vsxj5n9Mb54qdEg",
	authDomain: "learnone-ui-prod.firebaseapp.com",
	projectId: "learnone-ui-prod",
	storageBucket: "learnone-ui-prod.appspot.com",
	messagingSenderId: "7253755044",
	appId: "1:7253755044:web:1db1446bfbfccda4c15231",
	measurementId: "G-07SV585TBM",
};

export const fileUploadDirectory =
	process.env.NODE_ENV === "development" ? "pp_dev" : "payment_proofs";

// console checker (Console Checker is enable on development environment)
export const enableConsoleChecking = process.env.NODE_ENV !== "development";

// Payment types
export const paymentTypes = {
	CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
	BANK_TRANSFER: "BANK_TRANSFER",
	CARD_PAYMENT: "CARD_PAYMENT",
};

export const paymentStates = {
	VALIDATION_PENDING: "VALIDATION_PENDING",
	VALIDATED: "VALIDATED",
	REJECTED: "REJECTED",
};

export const paymentStateMessage = {
	VALIDATION_PENDING: lang("payment.validation.pending"),
	VALIDATED: lang("payment.validated"),
	REJECTED: lang("payment.rejected"),
};
