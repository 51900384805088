import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import Divider from "@material-ui/core/Divider";

import Copyright from "./Copyright";
import HowTos from "./GetInTouch";

const useStyles = makeStyles((theme) => ({
	footer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		//used to make the footer sticky
		flexShrink: "0",
	},
	divider: {
		marginTop: theme.spacing(1),
	},
}));

const Footer = () => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Divider className={classes.divider} variant="fullWidth" />
			<footer className={classes.footer}>
				<HowTos />
				<Typography
					variant="body1"
					align="center"
					color="textSecondary"
					component="h1"
				>
					{"Powered by "}
					<Link color="inherit" href="https://zeroonetech.xyz/">
						Zero One Technologies
					</Link>
				</Typography>
				<Grid
					container
					direction="column"
					justify="flex-start"
					alignItems="center"
				>
					<Grid item>
						<IconButton
							aria-label="facebook"
							href="https://www.facebook.com/zeroonetechnologiesxyz"
						>
							<FacebookIcon />
						</IconButton>
						<IconButton
							aria-label="instagram"
							href="https://www.instagram.com/zeroonetechnologies_"
						>
							<InstagramIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Copyright />
			</footer>
		</React.Fragment>
	);
};

export default Footer;
