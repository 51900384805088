//React imports
import React from "react";
import ReactDOM from "react-dom";

//Foundational util imports
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

//Mui imports
import CssBaseline from "@material-ui/core/CssBaseline";
import { responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";

import App from "./App";
import ScrollToTop from "./ScrollToTop";
import reportWebVitals from "./reportWebVitals";

//custom theme
import muiTheme from "./theme/theme";

//Reducers
import authReducer from "./store/reducers/auth";
import alertReducer from "./store/reducers/alert";

//Project hoc imports
import withFooter from "./hoc/withFooter";

import "./theme/index.css";

const composeEnhancers =
	(process.env.NODE_ENV === "development"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: null) || compose;

const rootReducer = combineReducers({
	auth: authReducer,
	alert: alertReducer,
});
const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(thunk))
);

const app = () => (
	<Provider store={store}>
		<BrowserRouter>
			<React.StrictMode>
				<ScrollToTop />
				<App />
			</React.StrictMode>
		</BrowserRouter>
	</Provider>
);

const AppWithFooter = withFooter(app);

const theme = responsiveFontSizes(muiTheme, {
	factor: 1.125,
});

const AppWithTheme = (
	<ThemeProvider theme={theme}>
		{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
		<CssBaseline />
		<AppWithFooter />
	</ThemeProvider>
);

ReactDOM.render(AppWithTheme, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
