import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#A37DB4",
		},
		secondary: {
			main: "#e6536e",
			light: "#fff5f8",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#fff",
			appBar: "#4fb7cc",
		},
		footer: {
			default: "linear-gradient(45deg, #A883BA 30%, #7C4D8F 90%)",
			text: "#ffffff",
		},
	},
});

export default theme;
