//React imports
import React, { Suspense, useEffect } from "react";

//Foundational util imports
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

//Project component imports
import Spinner from "./components/UI/Spinner";

//Project hoc imports
import withErrorHandler from "./hoc/withErrorHandler";
import Layout from "./hoc/Layout";

//Project utility, helper, constants imports
import axios from "./axios-mobile-class";
import * as routez from "./shared/routes";
import * as actions from "./store/actions/index";

//Project container imports
const LandingPage = React.lazy(() => {
	return import("./container/LandingPage");
});

const SubjectsOverView = React.lazy(() => {
	return import("./container/SubjectOverview/SubjectOverview");
});

const Auth = React.lazy(() => {
	return import("./container/Auth/Auth");
});

const MyProfile = React.lazy(() => {
	return import("./container/StudentProfile/StudentProfile");
});

const MySubjectsPage = React.lazy(() => {
	return import("./container/EnrolledSubjects/EnrolledSubjectsPage");
});

const PurchasesPage = React.lazy(() => {
	return import("./container/Purchases/PurchasesPage");
});

const VideoView = React.lazy(() => {
	return import("./container/VideoView");
});

const TopicExplainerVideo = React.lazy(() => {
	return import("./container/Topics/TopicExplainerVideo");
});

const AuthCheck = React.lazy(() => {
	return import("./container/CheckoutPayment/AuthCheck");
});

// TODO: Remove the following
// const CheckoutPage = React.lazy(() => {
// 	return import("./container/CheckoutPayment/CheckoutPage");
// });

const CheckoutPage = React.lazy(() => {
	return import("./container/CheckoutPayment/CheckoutPage");
});

const Topics = React.lazy(() => {
	return import("./container/Topics/Topics");
});

const Topic = React.lazy(() => {
	return import("./container/Topics/Topic");
});

const Subjects = React.lazy(() => {
	return import("./container/Subjects/SubjectsPage");
});

const NotFound = React.lazy(() => {
	return import("./container/InfoPages/404");
});

const Forbidden = React.lazy(() => {
	return import("./container/InfoPages/403");
});

const GenericInfoPage = React.lazy(() => {
	return import("./container/InfoPages/GenericInfo");
});

const SiteExplainerVideo = React.lazy(() => {
	return import("./container/InfoPages/SiteExplainerVideo");
});

// TODO: Remove the following
// const PaymentPage = React.lazy(() => {
// 	return import("./container/CheckoutPayment/PaymentPage");
// });

const PaymentPage = React.lazy(() => {
	return import("./container/CheckoutPayment/PaymentPage");
});

const CourseDetailsPage = React.lazy(() => {
	return import("./container/CourseDetails");
});

const PaymentProofPage = React.lazy(() => {
	return import("./container/Purchases/PaymentProof");
});

const VerifyEmailPage = React.lazy(() => {
	return import("./container/StudentProfile/VerifyEmailPage");
});

const ForgotPasswordPage = React.lazy(() => {
	return import("./container/Auth/ForgotPasswordPage");
});

const App = (props) => {
	const onTryAutoSignIn = props.onTryAutoSignIn;

	useEffect(() => {
		onTryAutoSignIn();
		document.addEventListener("contextmenu", (e) => {
			e.preventDefault();
		});
	}, [onTryAutoSignIn]);
	let routes = (
		<Suspense fallback={<Spinner />}>
			<Switch>
				<Route
					exact
					path={`${routez.LESSONS}/:lessonId`}
					component={VideoView}
				/>
				<Route
					exact
					path={`${routez.TOPIC_EXPLAINER}/:topicId`}
					component={TopicExplainerVideo}
				/>
				<Route exact path={routez.SIGNUP} component={Auth} />
				<Route exact path={routez.SIGNIN} component={Auth} />
				<Route
					exact
					path={`${routez.CHECKOUT}`}
					component={CheckoutPage}
				/>
				<Route
					path={`${routez.AUTH_CHECK}`}
					component={AuthCheck}
				/>
				<Route
					exact
					path={`${routez.PAYMENTS}`}
					component={PaymentPage}
				/>
				<Route exact path={routez.MY_PROFILE} component={MyProfile} />
				<Route
					exact
					path={routez.MY_SUBJECTS}
					component={MySubjectsPage}
				/>
				<Route
					exact
					path={routez.MY_PURCHASES}
					component={PurchasesPage}
				/>
				<Route
					exact
					path={`${routez.SUBJECT}/:id/${routez.TOPIC}/:topicId`}
					component={Topic}
				/>
				<Route
					exact
					path={`${routez.SUBJECT}/:id/${routez.TOPICS}`}
					component={Topics}
				/>
				<Route
					exact
					path={`${routez.SUBJECT}/:id`}
					component={SubjectsOverView}
				/>
				<Route exact path={routez.SUBJECT_LIST} component={Subjects} />
				<Route
					exact
					path={routez.COURSE_DETAILS}
					component={CourseDetailsPage}
				/>
				<Route
					exact
					path={routez.HOW_IT_WORKS}
					component={SiteExplainerVideo}
				/>
				<Route
					exact
					path={routez.PAYMENT_PROOF}
					component={PaymentProofPage}
				/>
				<Route
					exact
					path={routez.VERIFY_EMAIL}
					component={VerifyEmailPage}
				/>
				<Route
					exact
					path={routez.FORGOT_PASSWORD}
					component={ForgotPasswordPage}
				/>
				<Route exact path={routez.FORBIDDEN} component={Forbidden} />
				<Route
					exact
					path={routez.RESTRICTED}
					render={(props) => (
						<GenericInfoPage
							{...props}
							textContent="This is an unsupported browser, please use Chrome or Safari. Or, if you opened developer tools, you won't be able to use MobileClass. "
						/>
					)}
				/>
				<Route
					exact
					path={routez.PASSWORD_RESETTED}
					render={(props) => (
						<GenericInfoPage
							{...props}
							textContent="ඔබගේ මුරපදය (Password) සාර්ථකව යළි පිහිටුවන ලදි. ඔබගේ විද්යුත් තැපෑල (Email) පරීක්ෂා කරන්න."
						/>
					)}
				/>
				<Route exact path={routez.LANDING} component={LandingPage} />
				<Route component={NotFound} />
			</Switch>
		</Suspense>
	);

	return <Layout isAuthenticated={props.isAuthenticated}>{routes}</Layout>;
};

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSignIn: () => dispatch(actions.authCheckState()),
	};
};

const withErrorhandlerWrappedComponent = withErrorHandler(App, axios);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withErrorhandlerWrappedComponent);
