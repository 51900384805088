import {
	addItemToArray,
	removeItemFromArray,
	updateObject,
	INDEX,
} from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
	alerts: [],
};

const addAlert = (state, action) => {
	const alertState = {
		alerts: addItemToArray(state.alerts, action.alert),
	};

	return updateObject(state, alertState);
};

const removeAlert = (state, action) => {
	const alertState = {
		alerts: removeItemFromArray(state.alerts, INDEX, action.alertId),
	};

	return updateObject(state, alertState);
};

const clearAlerts = (state) => {
	const alertState = {
		alerts: [],
	};

	return updateObject(state, alertState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_ALERT:
			return addAlert(state, action);
		case actionTypes.REMOVE_ALERT:
			return removeAlert(state, action);
		case actionTypes.CLEAR_ALERT:
			return clearAlerts(state);
		default:
			return state;
	}
};

export default reducer;
